import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Typography,
  Badge,
  Image,
  Space,
  Form,
  Input,
  Switch,
} from "antd";
import { axios_json } from "../../axios";
import responseHandleSrv from "../../services/responseHandleSrv";
import { authHeader } from "../../services/auth-header";

function Branch() {
  const { Title, Text } = Typography;
  const [loadings, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [branchId, setBranchId] = useState(0);
  const [endPoint, setEndPoint] = useState("/api/branch/create");
  const [data, setData] = useState({});
  const [form] = Form.useForm();
  const params = useParams();
  let id = params.branchId;
  const saveData = (values) => {
    setIsLoading(true);
    let data = {
      code: values.code,
      name_en: values.name_en,
      name_th: values.name_th,
      is_active: values.is_active,
      branch_id: branchId,
    };

    console.log("data", data);

    setTimeout(() => {
      (async () => {
        await axios_json
          .post(`${endPoint}`, data, {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.data.success) {
              responseHandleSrv.handleSuccess(response);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            responseHandleSrv.handleError(err);
          });
      })();
    }, 3000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setBranchId(id);
    console.log(id);
    if (id !== "new") {
      getData();
      setEndPoint("/api/branch/update");
    }
  }, []);

  const getData = () => {
    (async () => {
      // setIsLoading(true)
      setPreload(true);
      await axios_json
        .get(`/api/branch/by-id/${id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            let res = response.data.data;
            setData(res);

            form.setFieldsValue({
              code: res.code,
              name_th: res.name_th,
              name_en: res.name_en,
              is_active: res.is_active,
            });
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title={`Branch : ${branchId}`}
            extra={[
              <Button
                type="primary"
                form="branch"
                htmlType="submit"
                loading={loadings}
              >
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <Form
                  form={form}
                  name="branch"
                  onFinish={saveData}
                  onFinishFailed={onFinishFailed}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  initialValues={{ remember: true }}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label={`Code`}
                        name={`code`}
                        rules={[
                          {
                            required: true,
                            message: "Please input code!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Code"
                          disabled={branchId !== "new"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label={`EN`}
                        name={`name_en`}
                        rules={[
                          {
                            required: true,
                            message: "Please input name en!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={`TH`}
                        required={true}
                        name={`name_th`}
                        rules={[
                          {
                            required: true,
                            message: "Please input name th!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="is_active"
                        label={
                          <label
                            style={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            active
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Active",
                          },
                        ]}
                      >
                        <Switch defaultValue="true" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Branch;
