import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Space,
  Form,
  Input,
  Select,
  Upload,
  Drawer, Pagination
} from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import ProductTable from "../../components/product/ProductTable";
import { axios_json, axios_form } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { min } from "draft-js/lib/DefaultDraftBlockRenderMap";

function Product() {
  const [loadings, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [preloadInv, setPreloadInv] = useState(false);
  const [loadingsInv, setIsLoadingInv] = useState(false);
  const [productId, setProductId] = useState(0);
  const [data, setData] = useState({});
  const [invList, setInvList] = useState([]);
  const [dataInv, setDataInv] = useState([]);
  const [catList, setCatList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [open, setOpen] = useState(false);
  const params = useParams();
  const { Option } = Select;
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const [rowData, setRowData] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [existImageFile, setExistImageFile] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateTh, setEditorStateTh] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);
  const [convertedContentTh, setConvertedContentTh] = useState(null);

  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
  });

  useEffect(() => {
    if (productId != 'new') {
      getData();
    }

  }, [productId]);

  useEffect(() => {
    if (productId != 'new') {
      getDataInventory(pageData.currentPage, pageData.pageSize)
    }
  }, [pageData.currentPage, pageData.pageSize]);

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(html);
  }, [editorState]);

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorStateTh.getCurrentContent()));
    setConvertedContentTh(html);
  }, [editorStateTh]);

  const getDataInventory = (page, pageSize) => {

    (async () => {
      setPreloadInv(true)
      await axios_json.get(`/api/inventory/list/by-product`, {
        headers: authHeader(),
        params: {
          product_id: productId,
          page,
          skip: pageSize
        }
      }).then(response => {
        // console.log('getDataInventory', response);
        if (response.data.success) {
          let res = response.data.data;
          setRowData(res.result);
          setInvList(res);
          setPageData((val) => ({
            ...val,
            totalcount: response.data.data.totalCount
          }));
          // prepareRows(res.result);
        }
        setPreloadInv(false)
      }).catch(err => {
        setPreloadInv(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };


  const getData = () => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/product/${productId}`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          console.log('res', res);

          // console.log('res',res)
          if (res) {
            setData(res);
            setFormValue(res);
            setEditImageFile(res.id, res.thumb_pic);
            if (res.description_en) {
              setEditDescriptionEn(res.description_en);
            }
            if (res.description_th) {
              setEditDescriptionTh(res.description_th)
            }

          }

        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const setEditImageFile = (id, picPath) => {
    if (picPath) {
      let path = picPath.split('/');
      let lh = path.length - 1;
      let arr = [];
      arr.push({
        uid: id,
        name: path[lh],
        status: 'done',
        url: picPath,
        thumbUrl: picPath,
      });
      console.log('arr', arr);
      setImageFile(arr);
      setExistImageFile([id]);
    }

  };

  const setEditDescriptionEn = (desc) => {
    const iniVal = desc;
    const contentBlock = htmlToDraft(iniVal);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };
  const setEditDescriptionTh = (desc) => {
    const iniVal = desc;
    const contentBlock = htmlToDraft(iniVal);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorStateTh(EditorState.createWithContent(contentState));
    } else {
      setEditorStateTh(EditorState.createEmpty());
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    console.log('values', values);

    // console.log('desc en', convertedContent);
    // console.log('desc th', convertedContentTh);

    let obj = {
      name_th: values.name_th !== undefined ? values.name_th : "",
      name_en: values.name_en !== undefined ? values.name_en : "",
      sku: values.sku !== undefined ? values.sku : "",
      description_en: convertedContent != null ? convertedContent : "",
      description_th: convertedContentTh != null ? convertedContentTh : "",
      uom: values.uom !== undefined ? values.uom : "",
      categories: values.categories !== undefined ? values.categories : "",
      price: values.price !== undefined ? values.price : 0,
      point: values.point !== undefined ? values.point : 0,
      exchange_rate: values.exchange_rate !== undefined ? values.exchange_rate : 0,
      is_active: true,
      is_delete: false,
      file: "",
      is_delete_file: false
    };

    let mockUp = imageFile ? (imageFile[0]?.originFileObj !== undefined ? imageFile[0].originFileObj : '') : '';

    console.log('mockUp', mockUp);
    if (mockUp) {
      obj.file = mockUp;
    }

    if (productId != 'new') {
      // console.log('data', data);
      obj.uuid = data.uuid;

      if (mockUp == '' && existImageFile.length == 0) {
        obj.is_delete_file = true;
      }
    }

    console.log('obj', obj);
    saveData(obj);
  };

  const saveData = (obj) => {
    console.log('saveData', obj);
    const upload = new FormData();

    upload.append('name_th', obj.name_th);
    upload.append('name_en', obj.name_en);
    upload.append('sku', obj.sku);
    upload.append('description_en', obj.description_en);
    upload.append('description_th', obj.description_th);
    upload.append('uom', obj.uom);
    upload.append('categories', obj.categories);
    upload.append('price', obj.price);
    upload.append('point', obj.point);
    upload.append('exchange_rate', obj.exchange_rate);
    upload.append('is_active', obj.is_active);
    upload.append('is_delete', obj.is_delete);
    upload.append('file', obj.file);
    upload.append('is_delete_file', obj.is_delete_file);

    if (obj.uuid) {
      upload.append('uuid', obj.uuid);
    }

    if (productId == 'new') {
      create(upload);
    }
    else {
      update(upload);
    }

  };

  const create = (upload) => {
    setIsLoading(true);
    (async () => {
      await axios_form.post(`/api/product/create`, upload, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false)
        responseHandleSrv.handleError(err);
      });
    })();
  };

  const update = (upload) => {
    setIsLoading(true);
    (async () => {
      await axios_form.put(`/api/product/${productId}`, upload, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }

        setIsLoading(false);

      }).catch(err => {
        setIsLoading(false);
        responseHandleSrv.handleError(err);
      });
    })();
  };

  const header = [
    {
      title: "Branch ID",
      key: "branch_id",
      dataIndex: "branch_id"
    },
    {
      title: "Branch Name",
      key: "branch_name",
      dataIndex: "branch_name_th"
    },
    {
      title: "Quantiry",
      key: "qty",
      dataIndex: "qty",
      render: (text, record) => (
        <>
          <Form.Item name={"qty"}
            rules={[{ required: true }]}
          >
            <Input defaultValue={text != null ? parseInt(text) : 0} onBlur={onChangeQty.bind(this, record)} />
          </Form.Item>
        </>
      )
    },
    {
      title: "Safety stock",
      key: "min_qty",
      dataIndex: "min_qty",
      render: (text, record) => (
        <>
          <Form.Item name={"min_qty"}
            rules={[{ required: true }]}
          >
            <Input defaultValue={text != null ? parseInt(text) : 0} onBlur={onChangeMinQty.bind(this, record)} />
          </Form.Item>
        </>
      )
    },
    {
      title: "Point",
      key: "redeem_point",
      dataIndex: "redeem_point",
      render: (text, record) => (
        <>
          <Form.Item name={"redeem_point"}
            rules={[{ required: true }]}
          >
            <Input defaultValue={text != null ? parseFloat(text) : 0} onBlur={onChangePoint.bind(this, record)} />
          </Form.Item>
        </>
      )
    },
  ];

  const getCategories = () => {
    (async () => {
      await axios_json.get(`/api/categories/dropdown`, {
        headers: authHeader()
      }).then(response => {
        if (response.data.success) {
          setCatList(response.data.data);
        }

      })
    })();
  };
  const getProductUom = () => {
    (async () => {
      await axios_json.get(`/api/product/get/uom`, {
        headers: authHeader()
      }).then(response => {
        if (response.data.success) {
          setUomList(response.data.data);
        }

      })
    })();
  };

  const setFormValue = (res) => {
    if (res) {
      form.setFieldsValue({
        'id': res.id,
        'sku': res.sku ? res.sku : '',
        'name_th': res.name_th ? res.name_th : '',
        'name_en': res.name_en ? res.name_en : '',
        // 'categories': res.categories ? res.categories : '',
        'point': res.point ? parseFloat(res.point) : 0,
        'exchange_rate': res.exchange_rate ? parseFloat(res.exchange_rate) : 0,
        'price': res.price ? parseFloat(res.price) : 0,
        'uom': res.uom ? res.uom : '',
        'image': res.thumb_pic ? res.thumb_pic : ''
      });

      if (res.categories) {
        let comma = res.categories.includes(',');
        if (comma) {
          let sp = res.categories.split(',')
          let arr = [];
          for (let i = 0; i < sp.length; i++) {
            arr.push(sp[i]);
          }
          form.setFieldsValue({
            'categories': arr,
          });

        } else {
          form.setFieldsValue({
            'categories': [res.categories],
          });
        }

      }
    }
  };

  const onChangeQty = (row, e) => {
    let arr = [];
    if (e.target.value) {

      let obj = objInv(row, e.target.value, row.redeem_point);
      if (dataInv.length === 0) {
        arr.push(obj);
        console.log('arr', arr);
        setDataInv(arr);
      } else {
        console.log('dataInv', dataInv);

        let find = dataInv.find(i => i.id === row.id);
        console.log('find', find);
        if (find) {
          find.qty = e.target.value;
          find.score_type_id = 2;
          // setDataInv(val => [...val, find]);
          setDataInv(dataInv);
        } else {
          let exist = dataInv.concat(obj);
          setDataInv(exist);
        }

      }
    }

  };
  const onChangeMinQty = (row, e) => {
    let arr = [];
    if (e.target.value) {

      let obj = objInv(row, row.min_qty,e.target.value);
      if (dataInv.length === 0) {
        arr.push(obj);
        console.log('arr min', arr);
        setDataInv(arr);
      } else {
        console.log('dataInv', dataInv);

        let find = dataInv.find(i => i.id === row.id);
        console.log('find', find);
        if (find) {
          find.min_qty = e.target.value;
          find.score_type_id = 2;
          // setDataInv(val => [...val, find]);
          setDataInv(dataInv);
        } else {
          let exist = dataInv.concat(obj);
          setDataInv(exist);
        }

      }
    }

  };

  const onChangePoint = (row, e) => {
    let arr = [];
    if (e.target.value) {
      let obj = objInv(row, row.qty, e.target.value);
      arr.push(obj);
      if (dataInv.length == 0) {
        setDataInv(arr)
      } else {
        let find = dataInv.find(i => i.id === row.id);
        // console.log('find point', find);
        if (find) {
          find.point = e.target.value;
          find.score_type_id = 2;
          // setDataInv(val => [...val, find]);
          setDataInv(dataInv);
        } else {
          let exist = dataInv.concat(obj);
          setDataInv(exist);
        }

      }
    }
  };

  const objInv = (row, qty, point) => {
    let obj = {
      id: row.id,
      product_id: row.product_id,
      branch_id: row.branch_id,
      branch_code: "",
      qty: qty === 0 ? row.qty : qty,
      point: point === 0 ? row.redeem_point : point,
      score_type_id: 2,
      min_qty: row.min_qty

    };
    return obj;
  };

  const saveDataInv = () => {
    console.log('data inv', dataInv);
    if (dataInv.length > 0) {
      saveInventory(dataInv);
    }
    // else {
    //   responseHandleSrv.handleMsg("Success");
    //       onClose();
    // }

  };
  const saveInventory = (dataInv) => {
    setIsLoadingInv(true);
    (async () => {
      await axios_json.post(`/api/inventory/stock/point`, dataInv, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {
          responseHandleSrv.handleSuccess(response);
          onClose();
        }
        setIsLoadingInv(false);
      }).catch(err => {
        setIsLoadingInv(false)
        responseHandleSrv.handleError(err);
      });
    })();
  };
  const showInventory = () => {
    setPreloadInv(true);
    setDataInv([]);
    setOpen(true);
    setRowData([]);
    setTimeout(() => {
      console.log("resolve");
      getDataInventory(pageData.currentPage, pageData.pageSize);
    }, 2000);


  };
  const onClose = () => {

    setOpen(false);
    setDataInv([]);
  };
  useEffect(() => {
    setProductId(params.productId);
    getCategories();
    getProductUom();
  }, []);

  const onChangeFileMockUp = (e) => {

    console.log('e', e);
    e.file.status = "done"
    setImageFile(e.fileList);
  }

  const onRemoveImageFile = (file) => {
    console.log('onRemoveImageFile', file);

    if (file?.originFileObj === undefined) {

      let index = existImageFile.indexOf(file.uid);

      let mockUp = [...existImageFile];

      mockUp.splice(index, 1);

      setExistImageFile(mockUp);
    }

    form.setFieldsValue({
      'image': ''
    });
  }
  const onChangePage = (page, pageSize) => {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));
  }

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title={`Product : ${productId}`}
            extra={[
              <Space>
                {productId !== 'new' &&
                  <Button
                    type="primary"
                    loading={loadings}
                    onClick={showInventory}
                  >
                    Inventory
                  </Button>
                }
                <Button type="primary" htmlType="submit" loading={loadings} form="product">
                  Save
                </Button>
              </Space>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <Form
                    form={form}
                    name="product"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    initialValues={{ remember: true }}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="SKU"
                          name={"sku"}
                          rules={[
                            {
                              required: true,
                              message: "Please input sku",
                            },
                          ]}
                        >
                          <Input disabled={productId !== 'new'} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Name TH"
                          name={"name_th"}
                          rules={[
                            {
                              required: true,
                              message: "Please input Name TH",
                            }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Name EN"
                          name={"name_en"}
                          rules={[
                            {
                              required: true,
                              message: "Please input Name EN",
                            }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="categories"
                          label="Category"
                          rules={[
                            {
                              required: true,
                              message: "Please select category",
                            }
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select a option and change input text above"
                            // allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              0
                            }
                          >
                            {catList && catList.map((s) => <Option value={s.code} key={s.code}>{s.name_th}</Option>)}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="uom"
                          label="UOM"
                          rules={[
                            {
                              required: true,
                              message: "Please select UOM",
                            }
                          ]}
                        >
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            {uomList && uomList.map((s) => <Option value={s.code} key={s.code}>{s.code}</Option>)}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Point"
                          name={"point"}
                          rules={[
                            {
                              required: true,
                              message: "Please input point",
                            }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Exchange" name={"exchange_rate"}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Price" name={"price"}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Description EN" name={"description_en"}>
                          {/* <Input /> */}
                          <Editor
                            editorStyle={{ border: "1px solid #f5f7f7", height: "350px" }}
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Description TH" name={"description_th"}>
                          {/* <Input /> */}
                          <Editor
                            editorStyle={{ border: "1px solid #f5f7f7", height: "350px" }}
                            editorState={editorStateTh}
                            onEditorStateChange={setEditorStateTh}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="image"
                        // label=""
                        // valuePropName="fileList"

                        >
                          <Upload
                            listType="picture"
                            maxCount={1}
                            // defaultFileList={[...imageFile]}
                            fileList={[...imageFile]}
                            onChange={onChangeFileMockUp}
                            // className="upload-list-inline"
                            onRemove={onRemoveImageFile}

                          >
                            <div className="btn-upload"><Button icon={<UploadOutlined />}>Upload</Button></div>
                          </Upload>
                        </Form.Item>
                        {/* <Dragger
                          listType="picture"
                          maxCount={1}
                          onChange={onChangeFileMockUp}
                          fileList={[...mockUpFile]}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger> */}
                      </Col>
                    </Row>
                  </Form>
                  <Drawer
                    title="Inventory & Points"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    getContainer={false}
                    width={`70%`}
                  >
                    <Card
                      title=""
                      extra={[
                        <Space>
                          <Button
                            type="primary"
                            loading={loadings}
                            onClick={saveDataInv}
                          >
                            Save
                          </Button>
                        </Space>,
                      ]}
                    >
                      {preloadInv ? (<Skeleton active />)
                        : (
                          <>
                            <Row>
                              <Col span={24}>
                                {rowData && <ProductTable header={header} data={rowData} />}
                              </Col>
                            </Row>

                            <Pagination
                              total={pageData.totalcount}
                              showSizeChanger
                              showQuickJumper
                              current={pageData.currentPage}
                              // defaultCurrent={1}
                              defaultPageSize={pageData.pageSize}
                              onChange={onChangePage}
                              responsive={true}
                              className="paginationset"
                            />
                          </>
                        )}

                    </Card>
                  </Drawer>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Product;
