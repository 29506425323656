import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Progress,
  Row,
  Typography,
  Table,
  Space,
  Flex,
  Button,
} from "antd";
import { BorderOutlined } from "@ant-design/icons";

export default function TableComponent(props) {
  const { Title } = Typography;
  return (
    <>
      <Space
        direction="vertical"
        size={20}
        span={24}
        style={{ display: "flex" }}
      >
        <Row gutter={[0, 10]}>
          <Col span={24} style={{ marginTop: 20 }}></Col>
        </Row>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Flex justify="left">
              <Title level={5}>Top 10 : </Title>
              {props.title}
            </Flex>
          </Col>
        </Row>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Table
              columns={props.columns}
              dataSource={props.data}
              pagination={false}
              className="ant-border-space"
            />
          </Col>
        </Row>
        <Flex justify="right">         
          <Link to="/realtime/detail/lower-stock">
            <Button color="primary" variant="outlined">
              view all
            </Button>
          </Link>
        </Flex>
      </Space>
    </>
  );
}
