import React, { useState, useEffect } from "react";
import { Col, Progress, Row,Typography } from "antd";

const ProgressComponent = (props) => {
  const { Title } = Typography;
  return (
    <>
      <Row>
        <Col span={24}>{props.title}</Col>
      </Row>
      <Row>
        <Col span={10}><Title level={3}>{props.value}%</Title></Col>
        <Col span={14}>
          <Progress
            type="circle"
            strokeColor={props.color}
            percent={props.value}
            showInfo={false}
            size={[150, 150]}
            strokeWidth={20}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProgressComponent;
