const conditionTypes = [
    {
      title: "DATE",
      description:
        "Trigger customer who has attributes which meet a condition grouping by Day / Month / Year.",
      type: "date",
    },
    {
      title: "DEMOGRAPHICS",
      description:
        "Trigger customers who has value of the selected demographic attribute which meet the condition.",
      type: "demographic",
    },
    {
      title: "ENGAGED WITH CAMPAIGN",
      description: "Trigger customers who has engaged the selected campaign.",
      type: "engagedWithCampaign",
    },
    {
      title: "MEET EVENTS",
      description: "Trigger customers who has even which meet the condition",
      type: "meetEvents",
    },
];

export default conditionTypes