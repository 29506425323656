import React, { useState, useEffect } from "react";
import {
  Form,
  DatePicker,
  Row,
  Col,
  Select,
  Input,
  Button,
  Space,
  Flex,
} from "antd";
import { axios_json, axios_form } from "../../axios";
import responseHandleSrv from "../../services/responseHandleSrv";
import { authHeader } from "../../services/auth-header";
import ProgressComponent from "../../components/report/progress.component";
import TableComponent from "../../components/report/table.component";
export default function Realtime() {
  const [filter, setFilter] = useState({
    search: "",
    branch_id: "",
    categories: "",
    start: "",
    end: "",
  });
  const [branchList, setBranchList] = useState([]);
  useEffect(() => {
    getBranchList();
    onClear();
  }, []);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const getBranchList = () => {
    (async () => {
      await axios_json
        .get(`/api/branch`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            // console.log('getBranchList',response.data);
            setBranchList(response.data.data);
          }
        });
    })();
  };
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      search: "",
      branch_id: "",
      categories: "",
      start: "",
      end: "",
    }));
  };
  const onFinish = (values) => {
    console.log("values", values);
    let product = values.product !== undefined ? values.product : "";
    let branch = values.branch !== undefined ? values.branch : "";
    let category = values.category !== undefined ? values.category : "";
    let rangePicker = values.range !== undefined ? values.range : "";

    let start_date = "";
    let end_date = "";

    if (rangePicker) {
      start_date = rangePicker[0].format("YYYY-MM-DD");
      end_date = rangePicker[1].format("YYYY-MM-DD");
    }
    console.log(start_date, end_date);
    setFilter((val) => ({
      ...val,
      search: product,
      branch_id: branch,
      categories: category,
      start: start_date,
      end: end_date,
    }));
  };
  const onChangePicker = (date, datestring) => {
    if (date == null) {
      setFilter((val) => ({
        ...val,
        start: "",
        end: "",
      }));
    }
  };
  const onChangeBranch = (value) => {
    if (value == null) {
      setFilter((val) => ({
        ...val,
        branch_id: "",
      }));
    }
  };
  const rangeConfig = {
    rules: [
      {
        type: "array",
        message: "Please select time!",
      },
    ],
  };
  const ProgressData = () => {
    return [
      { title: "cabinet 01", value: 10, color: "#108ee9" },
      { title: "cabinet 02", value: 20, color: "#87d068" },
      { title: "cabinet 03", value: 40, color: "#108ee9" },
      { title: "cabinet 04", value: 60, color: "#87d069" },
      { title: "cabinet 05", value: 80, color: "#108ff9" },
    ];
  };
  const columnsTop = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Stock Qty Limit",
      dataIndex: "stock_limit",
      key: "stock_limit",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Minimum",
      dataIndex: "minimum",
      key: "minimum",
    },
    {
      title: "Order Qty",
      dataIndex: "order_qty",
      key: "order_qty",
    },
    {
      title: "Stock remain",
      dataIndex: "stock_remain",
      key: "stock_remain",
    },
  ];
  const dataTop = [
    {
      key: "1",
      sku: "0001",
      product_name: "product A",
      stock_limit: "5",
      start_date: "2024-10-10",
      minimum: "2",
      order_qty: "10",
      stock_remain: "3",
    },
    {
      key: "2",
      sku: "0002",
      product_name: "product B",
      stock_limit: "5",
      start_date: "2024-10-11",
      minimum: "2",
      order_qty: "10",
      stock_remain: "3",
    },
    {
      key: "3",
      sku: "0003",
      product_name: "product C",
      stock_limit: "5",
      start_date: "2024-10-12",
      minimum: "2",
      order_qty: "10",
      stock_remain: "3",
    },
    {
      key: "4",
      sku: "0004",
      product_name: "product D",
      stock_limit: "5",
      start_date: "2024-10-13",
      minimum: "2",
      order_qty: "10",
      stock_remain: "3",
    },
    {
      key: "5",
      sku: "0005",
      product_name: "product E",
      stock_limit: "5",
      start_date: "2024-10-14",
      minimum: "2",
      order_qty: "10",
      stock_remain: "3",
    },
  ];

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} name="search">
      <Row gutter={[5, 5]} align={`bottom`}>
        <Col span={4}>
          <Form.Item name="range" label="Period" {...rangeConfig}>
            <RangePicker format={dateFormat} onChange={onChangePicker} />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name={`category`}
            label={`Category`}
            // rules={[
            //   {
            //     message: "Select catogory",
            //   },
            // ]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="ค้นหา"
              onChange={onChangeBranch}
            >
              {branchList &&
                branchList.map((s) => (
                  <Option value={s.id} key={s.id}>
                    {s.name_th}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={`category`}
            label={`Category`}
            // rules={[
            //   {
            //     message: "Select catogory",
            //   },
            // ]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="ค้นหา"
              onChange={onChangeBranch}
            >
              {branchList &&
                branchList.map((s) => (
                  <Option value={s.id} key={s.id}>
                    {s.name_th}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={`branch`}
            label={`Branch`}
            // rules={[
            //   {
            //     message: "Select Branch",
            //   },
            // ]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="ค้นหา"
              onChange={onChangeBranch}
            >
              {branchList &&
                branchList.map((s) => (
                  <Option value={s.id} key={s.id}>
                    {s.name_th}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" form="search">
                find
              </Button>
              <Button htmlType="button" onClick={onClear}>
                clear
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        {ProgressData().map((item) => (
          <Col span={4}>
            <ProgressComponent
              title={item.title}
              value={item.value}
              color={item.color}
            />
          </Col>
        ))}
      </Row>
      <TableComponent
        title="ตารางสาขาที่มีสต๊อกสินค้าจำนวนเหลือน้อย"
        columns={columnsTop}
        data={dataTop}
      />
      <TableComponent
        title="ตารางสาขาที่มีการใช้บริการมากที่สุด"
        columns={columnsTop}
        data={dataTop}
      />
    </Form>
  );
}
