import React, { act, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { axios_json } from "../axios";
import responseHandleSrv from "../services/responseHandleSrv";
import { authHeader } from "../services/auth-header";
import {
  Modal,
  Button,
  Row,
  Col,
  Card,
  Form,
  Input,
  Switch,
  Skeleton,
  DatePicker,
  Flex,
  Space,
} from "antd";
import {
  BorderlessTableOutlined,
  BorderOutlined,
  DeleteOutlined,
  ShakeOutlined,
} from "@ant-design/icons";
import SegementDate from "../components/segment/date.component";
import SegementDemographic from "../components/segment/demographic.component";
import SegementEngaged from "../components/segment/engaged.component";
import SegementMeet from "../components/segment/meet.component";
import conditionTypes from "../components/segment/filter.data";

const SegmentEdit = () => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [open, setOpen] = useState(false);
  const [objectList, setObjectList] = useState([]);
  const [objKey, setObjKey] = useState(0);

  const { segmentId } = useParams();
  const [id, setId] = useState(segmentId);
  const [segment, setSegment] = useState({});
  const history = useHistory();

  useEffect(async () => {
    console.log("id", id);
    await axios_json
      .get(`/api/segment/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success) {
          // responseHandleSrv.handleSuccess(response);
          setSegment(response.data.data);
          let row = response.data.data;
          fillForm(row);
          // createObject("date");
          const conditions = row.condition;
          let objArray = [];
          for (let i = 0; i < conditions.length; i++) {
            let type = conditions[i].type;
            console.log(`tyepx ${i} : ${type}`);
            if (type == "demo") {
              // createObject("demographic", conditions[i].condition);
              objArray.push({
                filterType: "demographic",
                index: i,
                value: conditions[i].condition,
              });
            }
            if (type == "engaged") {
              // createObject("engagedWithCampaign", conditions[i].condition);
              objArray.push({
                filterType: "engagedWithCampaign",
                index: i,
                value: conditions[i].condition,
              });
            }
            if (type == "meet") {
              // createObject("meetEvents", conditions[i].condition);
              objArray.push({
                filterType: "meetEvents",
                index: i,
                value: conditions[i].condition,
              });
            }
            if (type == "date") {
              // createObject("date", conditions[i].condition);
              objArray.push({
                filterType: "date",
                index: i,
                value: conditions[i].condition,
              });
            }

            console.log(conditions[i]);
          }
          setObjectList([...objArray]);
          // setObject List
        }
      })
      .catch((err) => {
        responseHandleSrv.handleError(err);
      });
  }, []);
  // console.log("segment", segment);
  const fillForm = (row) => {
    form.setFieldsValue({
      name: row.name,
      alias: row.alias,
      description: row.description,
      active: row.is_active ? true : false,
      refresh: row.refresh ? true : false,
    });
  };
  const createObject = (filterType, value = null) => {
    setObjectList([
      ...objectList,
      { filterType: filterType, index: objKey, value: value },
    ]);
    setObjKey(objKey + 1);
    setOpen(false);
    console.log(objectList);
  };
  const removeObject = (id) => {
    setObjectList([...objectList]);
    setObjectList(objectList.filter((item) => item.index !== id));
  };

  const getTypeTitle = (type) => {
    return conditionTypes.find((item) => item.type === type).title;
  };

  const submit = async (values) => {
    setLoadings(true);
    console.log("=== submit ===");
    console.log(values);
    let data = [];
    Object.keys(values).map((key, index) => {
      if (key.indexOf("filter_type_") !== -1) {
        data.push({
          id: parseInt(key.split("_")[key.split("_").length - 1]),
          type: values[key],
          condition: [],
        });
      }
    });
    data.map((item, index) => {
      let attrs = [];
      let operators = [];
      let vals = [];
      let conditions = [];
      Object.keys(values).map((key2, index) => {
        if (key2.indexOf(`attr_${item.type}_${item.id}_`) !== -1) {
          attrs.push(values[key2]);
        }
        if (key2.indexOf(`filter_${item.type}_${item.id}_`) !== -1) {
          let optVal = values[key2];
          if (typeof optVal == "object") {
            optVal = optVal.value;
          }
          if (optVal == "eq_tag") {
            optVal = "eq";
          } else if (optVal == "neq_tag") {
            optVal = "neq";
          } else {
            optVal = optVal;
          }
          operators.push(optVal);
        }
        if (key2.indexOf(`value_${item.type}_${item.id}_`) !== -1) {
          vals.push(values[key2]);
        }
      });
      for (let i = 0; i < attrs.length; i++) {
        conditions.push({
          attr: attrs[i].value ?? attrs[i],
          operator: operators[i].value ?? operators[i],
          value: vals[i].value ?? vals[i],
        });
      }
      data[index] = { ...item, condition: conditions };
    });
    let payload = {
      segment_id: segmentId,
      name: values.name,
      alias: values.alias,
      description: values.description,
      refresh: values.refresh === true ? 1 : 0,
      is_active: values.active === true ? 1 : 0,
      conditions: data,
    };
    console.log("payload : ", JSON.stringify(payload));
    await saveEdit(payload);
    setLoadings(false);
  };

  const saveEdit = async (payload) => {
    axios_json
      .post("/api/segment/update", payload, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.success) {
          responseHandleSrv.handleSuccess(response);
          history.push("/segmentation");
        }
      })
      .catch((err) => {
        responseHandleSrv.handleError(err);
      });
  };
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Segment Edit"
            extra={[
              <Button
                type="primary"
                loading={loadings}
                form="frmsegment"
                htmlType="submit"
              >
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <Form
                    {...{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
                    form={form}
                    name="frmsegment"
                    onFinish={submit}
                    onFinishFailed={() => console.log("Failed")}
                  >
                    <Row justify="center" gutter={[0, 10]}>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="name"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Name
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "fill name",
                              },
                            ]}
                          >
                            <Input placeholder="Name" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="alias"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                alias
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "please fill alias",
                              },
                            ]}
                          >
                            <Input placeholder="alias" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="description"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                description
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "description",
                              },
                            ]}
                          >
                            <Input.TextArea rows={6} />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="active"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                active
                              </label>
                            }
                            valuePropName="checked"
                          >
                            <Switch defaultChecked />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="refresh"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                refresh
                              </label>
                            }
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={22}>
                        {objectList &&
                          objectList.map((item) => (
                            <>
                              <Card
                                title="Segment Conditions"
                                style={{ margin: "20px 0 0 0" }}
                              >
                                <Flex justify="space-between">
                                  <h3>{getTypeTitle(item.filterType)}</h3>
                                  <Button
                                    danger
                                    onClick={() => removeObject(item.index)}
                                    style={{
                                      border: "none",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Flex>
                                {item.filterType === "date" && (
                                  <SegementDate
                                    key={item.index}
                                    id={item.index}
                                    initialValue={item.value}
                                  />
                                )}
                                {item.filterType === "demographic" && (
                                  <SegementDemographic
                                    key={item.index}
                                    id={item.index}
                                    initialValue={item.value}
                                  />
                                )}
                                {item.filterType === "engagedWithCampaign" && (
                                  <SegementEngaged
                                    key={item.index}
                                    id={item.index}
                                    initialValue={item.value}
                                  />
                                )}
                                {item.filterType === "meetEvents" && (
                                  <SegementMeet
                                    key={item.index}
                                    id={item.index}
                                    initialValue={item.value}
                                  />
                                )}
                              </Card>
                            </>
                          ))}
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={22}>
                        <Card>
                          <Button
                            type="dashed"
                            block
                            onClick={() => setOpen(true)}
                          >
                            Add Segement Conditions
                          </Button>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Button onClick={() => setOpen(true)}>TEST</Button> */}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Row gutter={[16, 24]}>
          {conditionTypes.map((item, _) => (
            <>
              <Col span={12}>
                <Card hoverable onClick={() => createObject(item.type)}>
                  <Flex vertical>
                    <h2>{item.title}</h2>
                    <p style={{ color: "#666" }}>{item.description}</p>
                  </Flex>
                </Card>
              </Col>
            </>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default SegmentEdit;
