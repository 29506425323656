import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Typography,
  Badge,
  Image,
  Pagination,
  Space,
  Modal,
  Form,
  Input,
} from 'antd';
// import moment from "moment";
import ProductTable from '../../components/product/ProductTable';
import face3 from '../../assets/images/face-3.jpg';
import { ColorFactory } from 'antd/es/color-picker/color';
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from '../../services/auth-header';
import { getCurrentUser } from '../../services/auth';
import moment from 'moment/moment';
import { formatNumberZero } from '../../services/function';

function Products() {
  let user = getCurrentUser();
  const { Title, Text } = Typography;
  const { confirm } = Modal;
  const [loadings, setIsLoading] = useState(false);
  const [lists, setList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [preload, setPreload] = useState(false);
  const [form] = Form.useForm();
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: '',
  });
  const [filter, setFilter] = useState({
    search: '',
  });
  useEffect(() => {
    getData(pageData.currentPage, pageData.pageSize, filter.search);
  }, [pageData.currentPage, pageData.pageSize, filter]);

  function onChangePage(page, pageSize) {
    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize,
    }));
  }

  const getData = (page, pageSize, search) => {
    (async () => {
      setPreload(true);
      await axios_json
        .get(`/api/product/search/list`, {
          headers: authHeader(),
          params: {
            search,
            page,
            skip: pageSize,
          },
        })
        .then((response) => {
          console.log('response', response);
          if (response.data.success) {
            let res = response.data.data;
            setList(res);
            setRowData(res.result);

            setPageData((val) => ({
              ...val,
              totalcount: response.data.data.totalCount,
            }));
            // prepareRows(res.result);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      search: '',
    }));
  };
  const onFinish = (values) => {
    console.log('values', values);
    let search = values.search !== undefined ? values.search : '';

    setFilter((val) => ({
      ...val,
      search: search,
    }));
    setPageData((val) => ({
      ...val,
      currentPage: 1,
    }));
  };
  const header = [
    {
      title: 'SKU',
      key: 'sku',
      dataIndex: 'sku',
    },
    {
      title: 'Product Name',
      dataIndex: 'name_th',
      key: 'name',
      width: '32%',
    },

    {
      title: 'Image',
      key: 'thumb_pic',
      dataIndex: 'thumb_pic',
      render: (text, record) => (
        <>{text ? <Image src={text} width={40} /> : ''}</>
      ),
    },
    {
      title: 'Create date',
      key: 'create_date',
      dataIndex: 'create_date',
      render: (text, record) => (
        <>{text ? moment(text).format('DD/MM/YYYY') : ''}</>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        // <>
        //   <Link to={`/product/${record.id}`}>
        //     <Button type="primary">Edit</Button>
        //   </Link>
        // </>
        <>
          <Space>
            <Link to={`/product/${record.id}`}>
              <Button type='primary'>Edit</Button>
            </Link>
            <Button
              type='primary'
              danger
              onClick={() => showModalDelete(record)}
            >
              Delete
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const showModalDelete = (values) => {
    confirm({
      title: 'คุณแน่ใจที่จะลบรายการนี้?',
      okText: 'ใช่',
      cancelText: 'ไม่',
      okType: 'danger',
      centered: true,
      closable: true,
      okButtonProps: { className: 'dangerOutlAnimate' },
      cancelButtonProps: { className: 'radiusBtn' },
      onOk() {
        deleteProduct(values);
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const deleteProduct = (values) => {
    console.log('values', values);

    (async () => {
      setIsLoading(true);
      await axios_json
        .delete(`/api/product/${values.id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            getData(pageData.currentPage, pageData.pageSize, '');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  return (
    <>
      <Form layout='vertical' form={form} onFinish={onFinish} name='search'>
        <Row gutter={[5, 5]} align={`bottom`}>
          <Col span={12}>
            <Form.Item name={`search`} label={`Search`}>
              <Input
                style={{ color: 'black', height: '30px', fontWeight: '400' }}
                placeholder='sku, ชื่อสินค้า'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Space>
                <Button type='primary' htmlType='submit' form='search'>
                  find
                </Button>
                <Button htmlType='button' onClick={onClear}>
                  clear
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ fontWeight: 'bold' }}>
        Total Count : {formatNumberZero(pageData.totalcount)}
      </div>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title='Products'
            extra={[
              <Link to='/product/new'>
                <Button type='primary'>Add New</Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  {rowData && (
                    <ProductTable
                      header={header}
                      data={rowData}
                      pageData={pageData}
                      onChangePage={onChangePage}
                    />
                  )}
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    // defaultCurrent={1}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className='paginationset'
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Products;
