import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Pagination,
  Form,
  Skeleton,
  Input,
  Flex,
  Table,
  Popconfirm,
  ConfigProvider,
} from "antd";
import { axios_json } from "../../axios";
import responseHandleSrv from "../../services/responseHandleSrv";
import { authHeader } from "../../services/auth-header";

export default function Tags() {
  const [preload, setPreload] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoadings(true);
    let data = {
      name: values.tag,
    };
    axios_json
      .post(`/api/tag/create`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success) {
          responseHandleSrv.handleSuccess(response);
        }
        form.resetFields();
        getData();
        setLoadings(false);
      })
      .catch((err) => {
        responseHandleSrv.handleError(err);
        setLoadings(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  /** Table */
  const columns = [
    {
      title: "Tag",
      dataIndex: "tag_name",
      key: "tag_name",
      width: "80%",
    },
    {
      title: "Action",
      dataIndex: "tag_id",
      key: "action",
      render: (text, record) => (
        <ConfigProvider
          button={{
            style: {
              width: 80,
              height: 40,
              margin: 4,
            },
          }}
        >
          <Popconfirm
            title="Delete tags"
            description="Are you sure to delete this tags?"
            okText="Yes"
            cancelText="No"
            style={{ width: "100%" }}
            placement="right"
            onConfirm={() => {
              deleteTag(record.tag_id);
            }}
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        </ConfigProvider>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const getData = () => {
    axios_json
      .get(`/api/tags`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success) {
          setData(response.data.data);
        }
      })
      .catch((err) => {
        responseHandleSrv.handleError(err);
      });
  };
  useEffect(() => {
    getData();
  }, []);
const deleteTag = (id) => {
  axios_json
    .delete(`/api/tag/delete/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.success) {
        responseHandleSrv.handleSuccess(response);
        getData();
      }
    })
    .catch((err) => {
      responseHandleSrv.handleError(err);
    });
}
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card title="Tags">
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Flex justify="flex-end" gap={10}>
                      <Form.Item
                        name="tag"
                        rules={[
                          {
                            required: true,
                            message: "Please input your tag!",
                          },
                        ]}
                      >
                        <Input placeholder="Tag Name" />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loadings}
                        >
                          Add Tag
                        </Button>
                      </Form.Item>
                    </Flex>
                    <Flex justify="flex-between" gap={10}>
                      <Table
                        style={{ width: "100%" }}
                        columns={columns}
                        dataSource={data}
                      />
                    </Flex>
                  </Form>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
