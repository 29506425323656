import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";
import {
  Row,
  Col,
  Form,
  Button,
  DatePicker,
  Input,
  Select,
  Flex,
  Space,
  Typography,
  Progress
} from "antd";
import { axios_json, axios_form } from "../../axios";
import responseHandleSrv from "../../services/responseHandleSrv";
import { authHeader } from "../../services/auth-header";
import moment from "moment/moment";

export const Redeem = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { Title, Text } = Typography;
  const [catList, setCatList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [loadings, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [data, setData] = useState([]);

  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [filter, setFilter] = useState({
    search: "",
    branch_id: "",
    categories: "",
    start: "",
    end: "",
  });

  useEffect(() => {
    getCategories();
    getBranchList();
    onClear();

  }, []);

  useEffect(() => {
    if (filter.branch_id != "" || filter.categories != "") {
      getDataProduct();
    } else {
      getData();
    }

  }, [filter]);

  const getData = () => {
    // console.log('getData filter', filter);
    (async () => {
      setPreload(true);
      await axios_json
        .get(`/api/score/history/report`, {
          headers: authHeader(),
          params: {
            search: filter.search,
            branch_id: filter.branch_id,
            categories: filter.categories,
            start: filter.start,
            end: filter.end,
          },
        })
        .then((response) => {
          // console.log("getData", response);
          if (response.data.success) {
            let res = response.data.data;
            if(res.length > 0)
            {
              res.forEach(el => {
                el.value = parseInt(el.value);
              });
            }
            setData(res);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const getDataProduct = () => {
    // console.log('getData product filter', filter);
    (async () => {
      setPreload(true);
      await axios_json
        .get(`/api/score/history/report/product`, {
          headers: authHeader(),
          params: {
            search: filter.search,
            branch_id: filter.branch_id,
            categories: filter.categories,
            start: filter.start,
            end: filter.end,
          },
        })
        .then((response) => {
          // console.log("getDataProduct", response);
          if (response.data.success) {
            let res = response.data.data;
            if(res.length > 0)
            {
              res.forEach(el => {
                el.value = parseInt(el.value);
              });
            }
            setData(res);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const getCategories = () => {
    (async () => {
      await axios_json
        .get(`/api/categories/dropdown`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            // console.log('getCategories',response.data);
            setCatList(response.data.data);
          }
        });
    })();
  };
  const getBranchList = () => {
    (async () => {
      await axios_json
        .get(`/api/branch`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            // console.log('getBranchList',response.data);
            setBranchList(response.data.data);
          }
        });
    })();
  };

  let config = {};
  if (filter.branch_id != "" || filter.categories != "") {
    config = {
      animate: { enter: { type: "growInY" } },
      data: data,
      xField: "sku",
      yField: "value",
      colorField: "sku",
      axis: {
        y: {
          title: "จำนวน",
        },
        x: {
          title: "SKU",
        },
      },
      legend: {
        titlePosition: "right",
      },
    };
  } else {
    config = {
      animate: { enter: { type: "growInY" } },
      data: data,
      xField: "branch_name",
      yField: "value",
      colorField: "branch_name",
      axis: {
        y: {
          title: "จำนวน",
        },
        x: {
          title: "สาขา",
        },
      },
      legend: {
        titlePosition: "right",
      },
    };
  }

  const rangeConfig = {
    rules: [
      {
        type: "array",
        message: "Please select time!",
      },
    ],
  };
  const onFinish = (values) => {
    console.log("values", values);
    let product = values.product !== undefined ? values.product : "";
    let branch = values.branch !== undefined ? values.branch : "";
    let category = values.category !== undefined ? values.category : "";
    let rangePicker = values.range !== undefined ? values.range : "";

    let start_date = "";
    let end_date = "";

    if (rangePicker) {
      start_date = rangePicker[0].format("YYYY-MM-DD");
      end_date = rangePicker[1].format("YYYY-MM-DD");
    }
    console.log(start_date, end_date);
    setFilter((val) => ({
      ...val,
      search: product,
      branch_id: branch,
      categories: category,
      start: start_date,
      end: end_date,
    }));
    
  };
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      search: "",
      branch_id: "",
      categories: "",
      start: "",
      end: "",
    }));
  };
  const onChangeBranch = (value) => {
    if(value == null)
    {
      setFilter((val) => ({
        ...val,
        branch_id: '',
      }));
      
    }
  };
  const onChangeCate = (value) => {
    if(value == null)
    {
      setFilter((val) => ({
        ...val,
        categories: '',
      }));
      
    }
  };
  const onChangeProduct = (e) => {
    if(e.target.value === "")
    {
      setFilter((val) => ({
        ...val,
        search: '',
      }));
    }
  };
  const onChangePicker = (date, datestring) => {
    if(date == null)
    {
      setFilter((val) => ({
        ...val,
        start: '',
        end: '',
      }));
      
    }
  };
  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish} name="search">
        <Row gutter={[5, 5]} align={`bottom`}>
          <Col span={6}>
            <Form.Item name="range" label="Period" {...rangeConfig}>
              <RangePicker format={dateFormat} onChange={onChangePicker}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={`branch`}
              label={`Branch`}
            // rules={[
            //   {
            //     message: "Select Branch",
            //   },
            // ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="ค้นหา"
                onChange={onChangeBranch}
              >
                {branchList &&
                  branchList.map((s) => (
                    <Option value={s.id} key={s.id}>
                      {s.name_th}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={`category`}
              label={`Category`}
            // rules={[
            //   {
            //     message: "Select catogory",
            //   },
            // ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="ค้นหา"
                onChange={onChangeCate}
              >
                {catList &&
                  catList.map((s) => (
                    <Option value={s.code} key={s.code}>
                      {s.name_th}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={`product`} label={`Product`}>
              <Input
                style={{ color: "black", height: "30px", fontWeight: "400" }}
                placeholder="ค้นหา"
                onChange={onChangeProduct}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" form="search">
                  find
                </Button>
                <Button htmlType="button" onClick={onClear}>
                  clear
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col span={24}>
          {data.length == 0 ? (
            <Title level={4} style={{ textAlign: "center" }}>
              Data Not Found.
            </Title>
          ) : (
            <Column {...config} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Redeem;
