import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge,
  Pagination,
  Form,
  DatePicker,
  Input,
  Space,
  Tag,
  Select,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';
import CustomerTable from '../../components/customer/CustomerTable';
import face2 from '../../assets/images/face-2.jpg';
import { ColorFactory } from 'antd/es/color-picker/color';
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from '../../services/auth-header';
import { getCurrentUser } from '../../services/auth';
import { formatNumberZero } from '../../services/function';
import moment from 'moment/moment';

function Redemption() {
  const { Title, Text } = Typography;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [lists, setList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: '',
  });
  const [filter, setFilter] = useState({
    action: '',
    customer: '',
    product: '',
    branch_id: '',
    score_type_id: '',
    categories: '',
    start: '',
    end: '',
  });
  useEffect(() => {
    onClear();
    getBranchList();
  }, []);

  useEffect(() => {
    getData(
      pageData.currentPage,
      pageData.pageSize,
      filter.action,
      filter.customer,
      filter.product,
      filter.branch_id,
      filter.score_type_id,
      filter.categories,
      filter.start,
      filter.end
    );
    console.log(filter);
  }, [pageData.currentPage, pageData.pageSize, filter]);
  const getData = (
    page,
    pageSize,
    action,
    customer,
    product,
    branch_id,
    score_type_id,
    categories,
    start,
    end
  ) => {
    (async () => {
      setPreload(true);
      await axios_json
        .get(`/api/score/history/report/redemption`, {
          headers: authHeader(),
          params: {
            action,
            customer,
            product,
            branch_id,
            score_type_id,
            categories,
            start,
            end,
            page,
            skip: pageSize,
          },
        })
        .then((response) => {
          console.log('response', response);
          if (response.data.success) {
            let res = response.data.data;
            setList(res);
            setRowData(res.result);

            setPageData((val) => ({
              ...val,
              totalcount: response.data.data.totalCount,
            }));
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const getBranchList = () => {
    (async () => {
      await axios_json
        .get(`/api/branch`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            // console.log('getBranchList',response.data);
            setBranchList(response.data.data);
          }
        });
    })();
  };
  const onChangePage = (page, pageSize) => {
    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize,
    }));
  };
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        message: 'Please select date!',
      },
    ],
  };
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      action: '',
      customer: '',
      product: '',
      branch_id: '',
      score_type_id: '',
      categories: '',
      start: '',
      end: '',
    }));
  };
  const onFinish = (values) => {
    console.log('values', values);
    let search = values.search !== undefined ? values.search : '';
    let product = values.product !== undefined ? values.product : '';
    let customer = values.customer !== undefined ? values.customer : '';
    let branch = values.branch !== undefined ? values.branch : '';
    let rangePicker = values.range !== undefined ? values.range : '';

    let start_date = '';
    let end_date = '';

    if (rangePicker) {
      start_date = rangePicker[0].format('YYYY-MM-DD');
      end_date = rangePicker[1].format('YYYY-MM-DD');
    }
    setFilter((val) => ({
      ...val,
      action: '',
      customer: customer,
      product: product,
      branch_id: branch,
      score_type_id: '',
      categories: '',
      start: start_date,
      end: end_date,
    }));

    setPageData((val) => ({
      ...val,
      currentPage: 1,
    }));
  };

  const exportExcel = () => {
    (async () => {
      setLoadings(true);
      await axios_json
        .get(`/api/score/history/export/redemption`, {
          responseType: 'arraybuffer',
          headers: authHeader(),
          params: {
            action: filter.action,
            customer: filter.customer,
            product: filter.product,
            branch_id: filter.branch_id,
            score_type_id: filter.score_type_id,
            categories: filter.categories,
            start: filter.start,
            end: filter.end,
          },
        })
        .then((response) => {
          console.log('export', response);
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            let date = moment().format('DDMMYYYY');
            let file_name = 'Redemption_Report_' + date;
            FileSaver.saveAs(blob, file_name);
            // downloadSrv.downloadFile(response, file_name);
            setLoadings(false);
          }
        })
        .catch((err) => {
          setLoadings(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const displayName = (row) => {
    if (row.firstname != '' && row.lastname != '') {
      return row.firstname + ' ' + row.lastname;
    } else {
      if (row.firstname != '') {
        return row.firstname;
      } else {
        return '';
      }
    }
  };
  const onChangePicker = (date, datestring) => {
    if (date == null) {
      setFilter((val) => ({
        ...val,
        start: '',
        end: '',
      }));
      setPageData((val) => ({
        ...val,
        currentPage: 1,
      }));
    }
  };
  const onChangeBranch = (value) => {
    if (value == null) {
      setFilter((val) => ({
        ...val,
        branch_id: '',
      }));
      setPageData((val) => ({
        ...val,
        currentPage: 1,
      }));
    }
  };
  const onChangeProduct = (e) => {
    if (e.target.value === '') {
      setFilter((val) => ({
        ...val,
        product: '',
      }));
      setPageData((val) => ({
        ...val,
        currentPage: 1,
      }));
    }
  };
  const onChangeCustomer = (e) => {
    if (e.target.value === '') {
      setFilter((val) => ({
        ...val,
        customer: '',
      }));
      setPageData((val) => ({
        ...val,
        currentPage: 1,
      }));
    }
  };
  const header = [
    // {
    //   title: "Redeem Id",
    //   key: "id",
    //   dataIndex: "id",
    // },
    {
      title: 'Redeemed Time',
      key: 'transaction_date',
      dataIndex: 'transaction_date',
      render: (text, record) => (
        <>{text ? moment(text).utc().format('DD/MM/YYYY HH:mm:ss') : ''}</>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => (
        <>
          {record.amount > 0 ? (
            // <span>Earned</span>
            <Tag color='green'>Earned</Tag>
          ) : (
            // <span>Redeemed</span>
            <Tag color='red'>Redeemed</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Reward / Ref.',
      key: 'product_name_th',
      dataIndex: 'product_name_th',
      render: (text, record) => (
        <>
          {record.amount > 0 ? (
            <span>-</span>
          ) : (
            <span>
              <p>{text}</p>
              <p>Ref.: {record.sku}</p>
            </span>
          )}
        </>
      ),
    },
    {
      title: 'Qty',
      key: 'qty',
      dataIndex: 'qty',
      render: (text, record) => (
        <>{text ? <span>{text}</span> : <span>-</span>}</>
      ),
    },
    {
      title: 'Point',
      key: 'amount',
      dataIndex: 'amount',
      render: (text, rec) => (
        <>
          {' '}
          {text <= 0 ? (
            <span style={{ color: 'red' }}>{formatNumberZero(text)}</span>
          ) : (
            <span style={{ color: 'green' }}>{formatNumberZero(text)}</span>
          )}
        </>
      ),
    },
    {
      title: 'Customer',
      key: 'mobile_no',
      dataIndex: 'mobile_no',
      render: (text, record) => (
        <div className='author-info'>
          <p>{displayName(record)}</p>
          <p>Mobile No.: {text}</p>
        </div>
      ),
    },
    {
      title: 'Shipping',
      key: 'Shipping',
      dataIndex: 'Shipping',
    },
  ];

  return (
    <>
      {/* <Card title="ค้นหา"> */}
      <Form layout='vertical' form={form} onFinish={onFinish} name='search'>
        <Row gutter={[5, 5]} align={`bottom`}>
          <Col span={6}>
            <Form.Item name='range' label='Period' {...rangeConfig}>
              <RangePicker format={dateFormat} onChange={onChangePicker} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={`branch`}
              label={`Branch`}
              // rules={[
              //   {
              //     message: "Select Branch",
              //   },
              // ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder='ค้นหา'
                onChange={onChangeBranch}
              >
                {branchList &&
                  branchList.map((s) => (
                    <Option value={s.id} key={s.id}>
                      {s.name_th}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={`product`} label={`Product`}>
              <Input
                style={{ color: 'black', height: '30px', fontWeight: '400' }}
                placeholder='ชื่อ, SKU'
                onChange={onChangeProduct}
                // allowClear
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={`customer`} label={`Customer`}>
              <Input
                style={{ color: 'black', height: '30px', fontWeight: '400' }}
                placeholder='ชื่อ, นามสกุล, เบอร์โทรศัพท์'
                onChange={onChangeCustomer}
                // allowClear
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Space>
                <Button type='primary' htmlType='submit' form='search'>
                  find
                </Button>
                <Button htmlType='button' onClick={onClear}>
                  clear
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ fontWeight: 'bold' }}>
        Total Count : {pageData.totalcount}
      </div>
      {/* </Card> */}
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title='Redemption'
            extra={[
              <Button type='primary' loading={loadings} onClick={exportExcel}>
                <DownloadOutlined />
                Download
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <CustomerTable header={header} data={rowData} />
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className='paginationset'
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Redemption;
