import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge,
  Pagination,
  Form,
  DatePicker,
  Input,
  Space,
  Checkbox,
  Table,
  Modal,
  Drawer,
  Flex,
} from "antd";
import {
  DownloadOutlined,
  FileExcelOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import CustomerTable from "../components/customer/CustomerTable";
import face2 from "../assets/images/face-2.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from "../axios";
import responseHandleSrv from "../services/responseHandleSrv";
import { authHeader } from "../services/auth-header";
import { getCurrentUser } from "../services/auth";
import { formatNumberZero } from "../services/function";
import moment from "moment/moment";

function Customer() {
  const { Title, Text } = Typography;
  const { RangePicker } = DatePicker;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [lists, setList] = useState([]);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: "",
    previousPage: "",
    nextPage: "",
    totalcount: "",
    goToPage: "",
  });
  const [filter, setFilter] = useState({
    search: "",
    SubDistrictId: "",
    DistrictId: "",
    ProvinceId: "",
    start: "",
    end: "",
  });
  useEffect(() => {
    onClear();
    setSelectedRowKeys([]);
  }, []);

  useEffect(() => {
    getData(
      pageData.currentPage,
      pageData.pageSize,
      filter.search,
      filter.SubDistrictId,
      filter.DistrictId,
      filter.ProvinceId,
      filter.start,
      filter.end
    );
    // console.log('filter', filter);
  }, [pageData.currentPage, pageData.pageSize, filter]);
  const getData = (
    page,
    pageSize,
    search,
    SubDistrictId,
    DistrictId,
    ProvinceId,
    start,
    end
  ) => {
    // console.log('search', search);
    (async () => {
      setPreload(true);
      await axios_json
        .get(`/api/customer/list`, {
          headers: authHeader(),
          params: {
            search,
            SubDistrictId,
            DistrictId,
            ProvinceId,
            start,
            end,
            page,
            skip: pageSize,
          },
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.success) {
            let res = response.data.data;
            setList(res);
            setRowData(res.result);

            setPageData((val) => ({
              ...val,
              totalcount: response.data.data.totalCount,
            }));
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const onChangePage = (page, pageSize) => {
    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize,
    }));
  };
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      search: "",
      SubDistrictId: "",
      DistrictId: "",
      ProvinceId: "",
      start: "",
      end: "",
    }));
  };
  const onFinish = (values) => {
    console.log("values", values);
    let search = values.search !== undefined ? values.search : "";
    // let date = values.create_date !== undefined ? values.create_date.format('YYYY-MM-DD') : "";

    let rangePicker = values.range !== undefined ? values.range : "";

    let start_date = "";
    let end_date = "";

    if (rangePicker) {
      start_date = rangePicker[0].format("YYYY-MM-DD");
      end_date = rangePicker[1].format("YYYY-MM-DD");
    }
    setFilter((val) => ({
      ...val,
      search: search,
      SubDistrictId: "",
      DistrictId: "",
      ProvinceId: "",
      start: start_date,
      end: end_date,
    }));
    setPageData((val) => ({
      ...val,
      currentPage: 1,
    }));
  };
  const displayName = (row) => {
    if (row.firstname != "" && row.lastname != "") {
      return row.firstname + " " + row.lastname;
    } else {
      if (row.firstname != "") {
        return row.firstname;
      } else {
        return "";
      }
    }
  };
  const header = [
    {
      title: "Name",
      dataIndex: "firstname",
      key: "firstname",
      width: "32%",
      render: (text, record) => (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={60}
              src={record.picture_url}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>{displayName(record)}</Title>
              <Link to={`/customer/history/${record.id}`}>
                <Text level={6} style={{ color: "#1890ff" }}>
                  {formatNumberZero(record.point)} points{" "}
                </Text>
              </Link>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (text, record) => (
        <div className="author-info">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Line connected",
      key: "isConnect",
      dataIndex: "isConnect",
      render: (text, record) => (
        <>
          <div style={{ textAlign: "center" }}>
            {record.line_uid === "" ? (
              <Badge status="default" />
            ) : (
              <Badge status="success" />
            )}
            {/* <Badge status="success" /> / <Badge status="default" /> */}
          </div>
        </>
      ),
    },
    {
      title: "Create date",
      key: "register_date",
      dataIndex: "register_date",
      render: (text, record) => (
        <>{text ? moment(text).format("DD/MM/YYYY") : ""}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <Link to={`/customer/${record.id}`}>
            <Button type="primary">Edit</Button>
          </Link>
        </>
      ),
    },
  ];
  const sendNotiLine = () => {
    // console.log('selectedRowKeys',selectedRowKeys);
    setLoadings(true);
    (async () => {
      await axios_json
        .post(`/api/utility/line/send-message/muti`, selectedRowKeys, {
          headers: authHeader(),
        })
        .then((response) => {
          // console.log('sendNotiLine' ,response)
          if (response.data.success) {
            responseHandleSrv.handleSuccess(response);
            setSelectedRowKeys([]);
          }
          setLoadings(false);
        })
        .catch((err) => {
          setLoadings(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.line_uid === "" && record.is_active === true,
      // Column configuration not to be checked
      name: record.firstname,
    }),
  };

  const onExport = () => {
    console.log("filter", filter);
    if (filter.search == "" && filter.start == "" && filter.end == "") {
      Modal.confirm({
        title: "คําเตือน",
        content: "คุณไม่ได้เลือกการกรองข้อมูล คุณต้องการดําเนินการต่อหรือไม่",
        okText: "ตกลง",
        cancelText: "ยกเลิก",
        onOk: () => {
          exportCustomer(filter);
        },
      });
    } else {
      exportCustomer(filter);
    }
  };
  const exportCustomer = (filter) => {
    axios_json
      .post(
        `/api/customer/export`,
        {
          search: filter.search ? filter.search : "",
          start: filter.start ? filter.start : "",
          end: filter.end ? filter.end : "",
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success) {
          responseHandleSrv.handleSuccess(response);
        }
      });
  };
  const [openDownload, setOpenDownload] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(true);
  const [downloadParams, setDownloadParams] = useState({
    offset: 0,
    limit: 50,
    file_status: 2,
  });
  const [dataDownload, setDataDownload] = useState([]);
  useEffect(() => {
    (async () => {
      setLoadingDownload(true);
      await axios_json
        .get(`/api/customer/downloads`, {
          headers: authHeader(),
          params: {
            offset: downloadParams.offset,
            limit: downloadParams.limit,
            file_status: downloadParams.file_status,
          },
        })
        .then((response) => {
          const data = response.data.data;
          console.log("data xxx : ", data);
          setDataDownload(data);
          setLoadingDownload(false);
        })
        .catch((err) => {
          setLoadingDownload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  }, [downloadParams]);
  const onOpenDownload = () => {
    setDownloadParams({
      offset: 0,
      limit: 50,
      file_status: 2,
    });
    setOpenDownload(true);
  };
  const headerDownload = [
    {
      title: "ชื่อไฟล์",
      dataIndex: "file_name",
      key: "filed_name",
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Action",
      dataIndex: "row_id",
      key: "row_id",
      width: 80,
      render: (text, record) => (
        <>
          <Flex justify={"space-around"}>
            <a
              href={`${process.env.REACT_APP_DOWNLOAD}/download/${record.file_name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadOutlined />
            </a>
            <DeleteOutlined
              onClick={() => {
                console.log(`delete id ${record.row_id}`);
                Modal.confirm({
                  title: "คําเตือน",
                  content: "คุณต้องการลบไฟล์นี้หรือไม่",
                  okText: "ตกลง",
                  cancelText: "ยกเลิก",
                  onOk: () => {
                    axios_json
                      .delete(`/api/customer/download/delete`, {
                        headers: authHeader(),
                        params: {
                          row_id: record.row_id,
                          file_name: record.file_name,
                        },
                      })
                      .then((response) => {
                        if (response.data.success) {
                          responseHandleSrv.handleSuccess(response);
                          setDownloadParams({
                            offset: 0,
                            limit: 50,
                            file_status: 2,
                          });
                        }
                      });
                  },
                });
              }}
            />
          </Flex>
        </>
      ),
    },
  ];

  return (
    <>
      {/* <Card title="ค้นหา"> */}
      <Form layout="vertical" form={form} onFinish={onFinish} name="search">
        <Row gutter={[5, 5]} align={`bottom`}>
          <Col span={6}>
            <Form.Item name={`search`} label={`Search`}>
              <Input
                style={{ color: "black", height: "30px", fontWeight: "400" }}
                placeholder="ชื่อ, นามสกุล, เบอร์โทรศัพท์"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="range" label="Create Date">
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" form="search">
                  find
                </Button>
                <Button htmlType="button" onClick={onClear}>
                  clear
                </Button>

                <Button
                  type="primary"
                  icon={<FileExcelOutlined />}
                  onClick={onExport}
                >
                  Export Excel
                </Button>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={onOpenDownload}
                >
                  Download
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ fontWeight: "bold" }}>
        Total Count : {formatNumberZero(pageData.totalcount)}
      </div>
      {/* </Card> */}
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Customers"
            // extra={[
            //   <Button type="primary" onClick={sendNotiLine} disabled={selectedRowKeys.length === 0}>
            //     Send notification line
            //   </Button>
            // ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <CustomerTable header={header} data={rowData} />
                  {/* <div className="table-responsive">
                    <Table
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                      columns={header}
                      rowKey={record => record.id}
                      dataSource={rowData}
                      pagination={false}
                      className="ant-border-space"
                    />
                  </div> */}
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <Drawer
        closable
        destroyOnClose
        title={<p>Download Files</p>}
        placement="right"
        open={openDownload}
        loading={loadingDownload}
        onClose={() => setOpenDownload(false)}
        width={800}
      >
        {dataDownload.length > 0 ? (
          <Table
            columns={headerDownload}
            dataSource={dataDownload}
            pagination={false}
            className="ant-border-space"
          />
        ) : (
          <div>ไม่พบข้อมูล</div>
        )}
      </Drawer>
    </>
  );
}

export default Customer;
