import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Form, Select, Input, Button } from "antd";
import { axios_json } from "../../axios";
import responseHandleSrv from "../../services/responseHandleSrv";
import { authHeader } from "../../services/auth-header";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SegementDemographic = (props) => {
  const [createObject, setCreateObject] = useState([]);
  const [objectNumber, setObjectNumber] = useState(1);
  const [tags, setTags] = useState([]);
  const getData = () => {
    axios_json
      .get(`/api/tags`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success) {
          setTags(response.data.data);
        }
      })
      .catch((err) => {
        responseHandleSrv.handleError(err);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const attrs = [
    { key: "contact_id", name: "Contact ID" },
    { key: "tags", name: "Tags" },
    { key: "customer_id", name: "Customer ID" },
    { key: "email", name: "Email" },
    { key: "sms", name: "SMS" },
    // { key: "facebook", name: "Facebook" },
    { key: "line", name: "Line" },
    { key: "birthdate", name: "Birthdate" },
    { key: "gender", name: "Gender" },
    { key: "member_card_no", name: "Member card no" },
    { key: "member_class", name: "Member class" },
    { key: "point", name: "Point" },
    { key: "register_channel", name: "Register channel" },
    { key: "register_date", name: "Register date" },
    { key: "firstname", name: "Firstname" },
    { key: "lastname", name: "Lastname" },
    // { key: "uuid", name: "Uuid" },
  ];
  const filters = [
    { key: "eq", name: "=", value: "" },
    { key: "lt", name: "<", value: "" },
    { key: "gt", name: ">", value: "" },
    { key: "gte", name: ">=", value: "" },
    { key: "nte", name: "<=", value: "" },
    { key: "neq", name: "Not equal", value: "" },
    { key: "eq_date", name: "=(date)", value: <DatePicker /> },
    { key: "lt_date", name: "<(date)", value: <DatePicker /> },
    { key: "gt_date", name: ">(date)", value: <DatePicker /> },
    { key: "gte_date", name: ">=(date)", value: <DatePicker /> },
    { key: "nte_date", name: "<=(date)", value: <DatePicker /> },
    { key: "neq_date", name: "!=(date)", value: <DatePicker /> },
    { key: "between", name: "between", $value: <RangePicker /> },
  ];
  const filterTags = [
    { key: "eq_tag", name: "=", value: "zzzzzz" },
    { key: "neq_tag", name: "Not equal", value: "xxxx" },
  ];

  useEffect(() => {
    if (props.initialValue != null && props.initialValue != undefined) {
      const initVal = props.initialValue;
      let objArray = [];
      let number = 0;
      initVal.map((item, index) => {
        console.log("intit: ", item);
        let attr = item.attr;
        let operator = item.operator;
        let val = item.value;
        number = index + 1;
        console.log("number", number);
        objArray.push({
          id: props.id + "_" + number,
          name: "demo_" + props.id + "_" + number,
          attr: attr,
          filter: attr == "tags" ? `${operator}_tag` : operator,
          value: operator=="between" || operator.indexOf("_date") > -1 ? [dayjs(val[0]), dayjs(val[1])] : val,
          options: attr == "tags" ? filterTags : filters,
        });
      });
      setCreateObject([...objArray]);
      setObjectNumber(number);
    } else {
      setCreateObject([
        ...createObject,
        {
          id: `${props.id}_${objectNumber}`,
          name: `demo_${props.id}_${objectNumber}`,
          attr: "contact_id",
          filter: "eq",
          value: "",
          options: filters,
        },
      ]);
      setObjectNumber(objectNumber + 1);
    }
    console.log("createObject", createObject);
  }, []);

  const onChangeFilter = (e) => {
    let idLen = e.key.split("_").length;
    let id =
      parseInt(e.key.split("_")[idLen - 2]) +
      "_" +
      parseInt(e.key.split("_")[idLen - 1]);
    let val = e.value;
    console.log(e.key.split("_").length);
    console.log(`id`, id, `val`, val, `key`, e.key);
    setCreateObject(
      createObject.map((item) => {
        console.log("item id :", item.id);
        if (item.id === id) {
          return { ...item, filter: val, value: val };
        }
        return item;
      })
    );
  };
  const createRule = () => {
    let number = objectNumber + 1;
    setObjectNumber(number);
    setCreateObject([
      ...createObject,
      {
        id: props.id + "_" + number,
        name: "demo_" + props.id + "_" + number,
        attr: "contact_id",
        filter: "eq",
        value: "",
        options: filters,
      },
    ]);
  };
  const onChangeAttr = ({ name, key, e }) => {
    console.log(`name : ${name} , key : ${key}`);
    let filterOptions = filters;
    if (key === "tags") {
      filterOptions = filterTags;
    }
    let idLen = name.split("_").length;
    let id =
      parseInt(name.split("_")[idLen - 2]) +
      "_" +
      parseInt(name.split("_")[idLen - 1]);
    let val = e.value;
    console.log(e.key.split("_").length);
    console.log(`id`, id, `val`, val, `key`, e.key);
    setCreateObject(
      createObject.map((item) => {
        console.log("item id :", item.id, "id", id);
        if (item.id === id) {
          console.log("item is tags");
          return { ...item, filter: val, value: val, options: filterOptions };
        }
        return item;
      })
    );
  };
  const deleteField = (id) => {
    setCreateObject(createObject.filter((item) => item.id !== id));
  };
  const field = ({
    id: id,
    name: name,
    attr: attr,
    filter: filter,
    value: value,
    options: options,
  }) => {
    const _filter = filters.find((e) => {
      return e.key === filter;
    });
    console.log(attr);
    console.log("filter x", filter);
    let input = <Input />;
    if (filter.indexOf("_date") > -1) {
      console.log("dt");
      input = <DatePicker />;
    } else if (filter.indexOf("between") > -1) {
      console.log("bt");
      input = <RangePicker />;
    }
    if (filter == "tags" || filter == "eq_tag" || filter == "neq_tag") {
      input = (
        <Select labelInValue>
          {tags.map((item) => (
            <Option key={item.tag_id} value={item.tag_id}>
              {item.tag_name}
            </Option>
          ))}
        </Select>
      );
    }
    return (
      <Row>
        <Col span={8}>
          <Form.Item name={`attr_` + name} initialValue={attr}>
            <Select
              labelInValue
              defaultValue={{ value: attr }}
              onChange={(e) =>
                onChangeAttr({ name: `attr_` + name, key: e.key, e: e })
              }
            >
              {attrs.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={`filter_` + name} initialValue={filter}>
            <Select
              id={`filter_` + name}
              labelInValue
              defaultValue={{ value: filter }}
              onChange={onChangeFilter}
            >
              {options.map((f) => (
                <Option key={`${f.key}_${id}`} value={f.key}>
                  {f.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={`value_` + name} initialValue={value}>
            {/* {filter.indexOf("_date") > -1 ? <DatePicker /> : <Input />} */}
            {input}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button type="primary" danger onClick={() => deleteField(id)}>
            Delete
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {createObject.map((items) =>
        field({
          id: items.id,
          name: items.name,
          attr: items.attr,
          filter: items.filter,
          value: items.value,
          options: items.options,
        })
      )}
      <Row>
        <Col span={24}>
          <Form.Item
            name={"filter_type_" + props.id}
            defaultValue={"demo"}
            initialValue={"demo"}
          >
            <Input type="hidden" value={"date"} />
          </Form.Item>
          <Button
            id="addRule"
            name="addRule"
            type="primary"
            block
            onClick={createRule}
          >
            AND
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default SegementDemographic;
