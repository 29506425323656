import React, { useState, useEffect, useMemo } from "react";
import { DatePicker, Row, Col, Form, Select, Input, Button } from "antd";
import { axios_json } from "../../axios";
import { authHeader } from "../../services/auth-header";

const SegementEngaged = (props) => {
  const [camapigns, setCampaigns] = useState([]);
  const [createObject, setCreateObject] = useState([]);
  const [objectNumber, setObjectNumber] = useState(1);
  const { Option } = Select;

  const filters = [
    {
      key: "dd",
      name: "days",
      value: Array.from({ length: 31 - 1 + 1 }, (_, i) => 1 + i),
    },
    {
      key: "mm",
      name: "month",
      value: Array.from({ length: 12 - 1 + 1 }, (_, i) => 1 + i),
    },
  ];
  /**
   * Initial values from props.initialValues
   * @type {object}
   * @default {}
   * @name initialValues
   * @memberof SegementEngaged
   */
  const initialValues = ({ props = null, res = null }) => {
    if (props.initialValue !== null && props.initialValue != undefined) {
      let objArr = [];
      const initVal = props.initialValue;
      initVal.map((item, index) => {
        let attr = item.attr;
        let operator = item.operator;
        let val = item.value;
        let number = index + 1;
        console.log("number", number);
        setObjectNumber(number);
        objArr.push({
          id: props.id + "_" + number,
          name: "engaged_" + props.id + "_" + number,
          attr: attr,
          filter: operator,
          value: val,
        });
      });
      setCreateObject([...objArr]);
    } else {
      setCreateObject([
        ...createObject,
        {
          id: `${props.id}_${objectNumber}`,
          name: `engaged_${props.id}_${objectNumber}`,
          attr: res[0].id,
          filter: "dd",
          value: "",
        },
      ]);
      setObjectNumber(objectNumber + 1);
    }
  };

  useEffect(async () => {
    const res = await getCampaigns();
    setCampaigns(res);
    res.length > 0 && initialValues({ props: props, res: res });
  }, []);

  const getCampaigns = async () => {
    let data = [];
    const res = await axios_json.get("/api/campaign/list", {
      headers: authHeader(),
      params: {
        page: 1,
        skip: 1000,
      },
    });
    if (await res.data.success) {
      data = await res.data.data.result;
    }
    return data;
  };
  const onChangeFilter = (e) => {
    let idLen = e.key.split("_").length;
    let id =
      parseInt(e.key.split("_")[idLen - 2]) +
      "_" +
      parseInt(e.key.split("_")[idLen - 1]);
    let val = e.value;
    console.log(e.key.split("_").length);
    console.log(`id`, id, `val`, val, `key`, e.key);
    setCreateObject(
      createObject.map((item) => {
        console.log("item id :", item.id);
        if (item.id === id) {
          return { ...item, filter: val, value: val };
        }
        return item;
      })
    );
  };
  const createRule = () => {
    console.log(camapigns);
    let number = objectNumber + 1;
    setObjectNumber(number);
    setCreateObject([
      ...createObject,
      {
        id: props.id + "_" + number,
        name: "engaged_" + props.id + "_" + number,
        attr: camapigns[0].id,
        filter: "dd",
        value: "",
      },
    ]);
  };
  const deleteField = (id) => {
    setCreateObject(createObject.filter((item) => item.id !== id));
  };
  const field = ({
    id: id,
    name: name,
    attr: attr,
    filter: filter,
    value: value,
  }) => {
    const _filter = filters.find((e) => {
      return e.key === filter;
    });
    console.log(attr);
    return (
      <Row>
        <Col span={8}>
          <Form.Item name={`attr_` + name} initialValue={attr}>
            <Select labelInValue defaultValue={{ value: attr }}>
              {camapigns.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={`filter_` + name} initialValue={filter}>
            <Select
              id={`filter_` + name}
              labelInValue
              defaultValue={{ value: filter }}
              onChange={onChangeFilter}
            >
              {filters.map((f) => (
                <Option key={`${f.key}_${id}`} value={f.key}>
                  {f.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={`value_` + name} initialValue={value}>
            <Select labelInValue defaultValue={value}>
              {_filter.value.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button type="primary" danger onClick={() => deleteField(id)}>
            Delete
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {createObject.map((items) =>
        field({
          id: items.id,
          name: items.name,
          attr: items.attr,
          filter: items.filter,
          value: items.value,
        })
      )}
      <Row>
        <Col span={24}>
          <Form.Item
            name={"filter_type_" + props.id}
            defaultValue={"engaged"}
            initialValue={"engaged"}
          >
            <Input type="hidden" value={"date"} />
          </Form.Item>
          <Button
            id="addRule"
            name="addRule"
            type="primary"
            block
            onClick={createRule}
          >
            AND
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default SegementEngaged;
